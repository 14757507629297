<template>
  <div
    class="details-modal h-100 px-5 d-flex justify-space-between align-center flex-column"
  >
    <div class="text-center mt-16">
      <h1 class="font-32 font-700">Congratulations!</h1>
      <p class="font-22">
        You've unlocked the Offers Page, your in-store digital showcase.
      </p>
    </div>

    <div class="px-10 py-6 w-100">
      <p class="font-16 font-500 text_grey text-center">
        please fill out the required information on the next page.
      </p>

      <v-btn
        class="mt-6 radius-15"
        height="45px"
        color="primary"
        elevation="0"
        block
        @click="$emit('next')"
        ><span class="white--text font-16">Next</span></v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  mounted() {
    this.details.name = this.profile?.name || "";
    this.details.address = this.profile?.location.address || "";
    this.details.slogan = this.profile?.slogan || "";
  },
  methods: {
    async submitHandler() {
      this.loading = true;
      let valid = this.$refs.groupForm.validate();

      if (!valid) {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: "Please fill all fields",
          color: "error",
        });
        return;
      }

      let payload = new FormData();
      for (let key in this.details) {
        payload.append(key, this.details[key]);
      }
      const res = await this.$axios.patch(process.env.VUE_APP_PROFILE, payload);
      if (res.data) {
        this.loading = false;
        this.$store.dispatch("setProfile");
        this.$store.dispatch("showSnack", {
          text: "Registered successfully",
          color: "success",
        });
        this.$emit("close");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    async cropSuccess(imgDataUrl) {
      this.imgDataUrl = imgDataUrl;
      this.uploadImage = false;

      this.uploadLogo(imgDataUrl).then(() => {
        // this.$emit("close");
      });
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    // async getProfile() {
    //   let urlApi = process.env.VUE_APP_PROFILE;
    //   // ToDo: make sure that profile endpoint is the same for users and admins
    //   // if (this.role == "user") {
    //   //   urlApi = process.env.VUE_APP_PROFILE;
    //   // }
    //   let { data } = await this.$axios.get(urlApi);
    //   if (data.success) {
    //     this.$store.dispatch("setProfile", data[this.role || "user"]);
    //   }
    // },
  },
};
</script>

<style lang="scss">
.details-modal {
  .upload-logo {
    height: 100px;
    width: 100px !important;
  }

  .details__input {
    border-radius: 10px;
    background: #eee;

    .v-input__slot {
      fieldset {
        border: none !important;
      }
    }

    &.address {
      .v-input {
        align-items: center;
      }

      .v-input__slot {
        padding-inline: 0 !important;
      }
    }

    .v-input__prepend-outer {
      margin-inline: 10px;
    }
  }
}
</style>
