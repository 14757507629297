<template>
  <div class="details-modal">
    <div
      class="d-flex align-center justify-space-between px-10 py-3 darkbg-grey"
    >
      <span class="font-22">Page Details</span>
      <img
        @click="$emit('close')"
        :src="require('@/assets/images/icons/close.svg')"
        class="cursor-pointer"
      />
    </div>
    <div class="bg-grey px-10 py-6">
      <v-form v-model="valid" ref="groupForm" @submit.prevent="submitHandler">
        <div>
          <upload-btn
            class="upload-logo"
            text="Logo image"
            @click="uploadImage = true"
            :image="profile.logo ? profile.logo.url : ''"
            :image64="profile.logo ? profile.logo.url : imgDataUrl"
            :key="fileKey"
            :cropImage="true"
          ></upload-btn>
          <span class="font-14 font-400 d-block black--text mt-2"
            >Logo Or Image</span
          >
          <span class="font-10 font-400 d-block greytext--text"
            >JPG, <span class="font-600">PNG*</span></span
          >
        </div>

        <base-input
          class="my-2 details__input"
          v-model="details.name"
          placeholder="Name"
          :hide-details="true"
          required="true"
        >
        </base-input>

        <!-- <base-input
          class="my-2 details__input"
          v-model="details.slogan"
          placeholder="Slogan"
          :hide-details="true"
          required="true"
        >
        </base-input> -->

        <base-input
          class="my-2 details__input address d-flex align-center"
          v-model="details.address"
          placeholder="Address"
          :hide-details="true"
          required="true"
          icon="mdi-map-marker"
        >
        </base-input>

        <v-btn
          class="mt-6 radius-15"
          height="45px"
          color="primary"
          elevation="0"
          block
          type="submit"
          :loading="loading"
          :disabled="loading"
          ><span class="white--text font-16">Save</span></v-btn
        >
      </v-form>
    </div>

    <v-dialog
      v-model="uploadImage"
      content-class="no-shadow white-bg radius-15 relative upload-dialog"
    >
      <my-upload
        field="img"
        @crop-success="cropSuccess"
        :width="300"
        :height="300"
        img-format="png"
        langType="en"
        :noSquare="true"
      ></my-upload>
      <img
        width="15px"
        height="15px"
        :src="require('@/assets/images/icons/close.svg')"
        class="upload-cancel cursor-pointer"
        @click="uploadImage = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import myUpload from "vue-image-crop-upload";
import UploadBtn from "@/components/base/UploadBtn.vue";

export default {
  components: { UploadBtn, myUpload },
  props: {},
  data: () => ({
    loading: false,
    details: {
      name: "",
      address: "",
      file: {},
      // slogan: "",
    },
    fileKey: false,
    file: {},
    valid: false,
    uploadImage: false,
    imgDataUrl: "",
  }),
  mounted() {
    this.details.name = this.profile?.name || "";
    this.details.address = this.profile?.location.address || "";
    this.details.slogan = this.profile?.slogan || "";
  },
  methods: {
    async submitHandler() {
      this.loading = true;
      let valid = this.$refs.groupForm.validate();

      if (!valid) {
        this.loading = false;
        this.$store.dispatch("showSnack", {
          text: "Please fill all fields",
          color: "error",
        });
        return;
      }

      let payload = new FormData();
      for (let key in this.details) {
        payload.append(key, this.details[key]);
      }
      const res = await this.$axios.patch(process.env.VUE_APP_PROFILE, payload);
      if (res.data) {
        this.loading = false;
        this.$store.dispatch("setProfile");
        this.$store.dispatch("showSnack", {
          text: "Registered successfully",
          color: "success",
        });

        if (this.profile && !this.profile.isFirstTimePremium) {
          this.$store.dispatch("alterFirstTimePremium");
        }

        this.$emit("close");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    async cropSuccess(imgDataUrl) {
      this.imgDataUrl = imgDataUrl;
      this.uploadImage = false;

      this.uploadLogo(imgDataUrl).then(() => {
        // this.$emit("close");
      });
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    // async getProfile() {
    //   let urlApi = process.env.VUE_APP_PROFILE;
    //   // ToDo: make sure that profile endpoint is the same for users and admins
    //   // if (this.role == "user") {
    //   //   urlApi = process.env.VUE_APP_PROFILE;
    //   // }
    //   let { data } = await this.$axios.get(urlApi);
    //   if (data.success) {
    //     this.$store.dispatch("setProfile", data[this.role || "user"]);
    //   }
    // },
  },
};
</script>

<style lang="scss">
.details-modal {
  .upload-logo {
    height: 100px;
    width: 100px !important;
  }

  .details__input {
    border-radius: 10px;
    background: #eee;

    .v-input__slot {
      fieldset {
        border: none !important;
      }
    }

    &.address {
      .v-input {
        align-items: center;
      }

      .v-input__slot {
        padding-inline: 0 !important;
      }
    }

    .v-input__prepend-outer {
      margin-inline: 10px;
    }
  }
}
</style>
