<template>
  <div>
    <v-container class="offers-page mt-9 mx-auto mx-lg-0 px-5 px-md-0">
      <v-row class="header font-30 mx-auto py-6 px-6" v-ripple>
        <div
          class="font-weight-medium text-center text-md-start col-12 col-md-3 pa-0"
        >
          Offers Page
        </div>
        <div
          class="col-md-9 filters d-flex flex-wrap text-center justify-md-end justify-center align-center pa-0"
        >
          <div class="col-auto text-md-end text-center pa-0">
            <v-switch
              @click.stop="toggleOffersPage"
              class="my-md-0 my-5 mx-0 radius-5"
              :hide-details="true"
              inset
              v-model="isOfferPageActive"
            ></v-switch>
          </div>
          <div class="col-auto text-md-end text-center pa-0">
            <v-btn class="radius-5" @click="openShareDialog">
              <img
                class="mr-2"
                :src="require('@/assets/images/icons/share.svg')"
                alt="Details"
              />
              Share</v-btn
            >
          </div>
          <div class="col-auto text-md-end text-center pa-0">
            <v-btn class="ml-3 radius-5" @click="openDetailsDialog">
              <img
                class="mr-2"
                :src="require('@/assets/images/icons/location-store.svg')"
                alt="Details"
              />
              Page Details</v-btn
            >
          </div>
        </div>
      </v-row>
      <v-row v-if="groups.length" class="mx-auto py-6 offers-content">
        <div class="section" v-for="(group, i) in groups" :key="i">
          <div
            class="my-5"
            v-if="group.products.some((prod) => prod.item.isOffer)"
          >
            <h2>{{ group.name }}</h2>
          </div>
          <v-row class="mt-4 card-parent flex-wrap align-start">
            <v-col
              v-for="product in group.products.filter(
                (prod) => prod.item.isOffer
              )"
              :key="product.item._id"
              lg="4"
              md="6"
              cols="12"
            >
              <offer-card :product="product" />
            </v-col>
          </v-row>
        </div>
      </v-row>

      <div v-else class="pa-10 mt-5 font-20 font-600 w-100 text-center">
        No offers to show here yet!
      </div>
    </v-container>

    <v-dialog v-model="shareDialog" content-class="white" max-width="450px">
      <share-offers-modal @close="shareDialog = false" />
    </v-dialog>

    <v-dialog v-model="detailsDialog" content-class="white" max-width="450px">
      <details-modal @close="detailsDialog = false" />
    </v-dialog>

    <v-dialog
      v-model="congratsDialog"
      content-class="white congrats-dialog"
      max-width="450px"
      persistent
    >
      <congrats-dialog @next="congratsNextStep" />
    </v-dialog>
  </div>
</template>

<script>
import ShareOffersModal from "./components/ShareOffersModal.vue";
import DetailsModal from "./components/DetailsModal.vue";
import CongratsDialog from "./components/CongratsDialog.vue";
import OfferCard from "./components/OfferCard.vue";

export default {
  name: "OffersDashboard",
  components: {
    ShareOffersModal,
    DetailsModal,
    CongratsDialog,
    OfferCard,
  },
  data: () => ({
    groups: [],
    products: [],
    ActiveProducts: true,
    shareDialog: false,
    detailsDialog: false,
    congratsDialog: false,
    isOfferPageActive: true,
  }),
  computed: {
    currency() {
      return this.$store.state.profile?.currency?.symbol;
    },
  },
  methods: {
    congratsNextStep() {
      this.congratsDialog = false;
      this.detailsDialog = true;
    },
    async toggleOffersPage() {
      let payload = new FormData();
      payload.append("isOfferPageActive", this.isOfferPageActive);
      const res = await this.$axios.patch(process.env.VUE_APP_PROFILE, payload);
      if (res.data) {
        this.loading = false;
        this.$store.dispatch("setProfile");
        this.$store.dispatch("showSnack", {
          text: "Updated successfully",
          color: "success",
        });
        this.$emit("close");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    ActiveFilter() {
      // this.ActiveProducts = !this.ActiveProducts;
    },
    async getGroups() {
      this.groups = [];
      let res;
      res = await this.$axios.get(
        `${process.env.VUE_APP_GROUPS}?isOffer=true&ispdeleted=false&isdeleted=false`
      );

      if (res.data.success) {
        this.groups = res.data.Categories;
        // this.groups = res.data.Categories.filter(g => g.products.length > 0);
      }
    },
    openShareDialog() {
      this.shareDialog = true;
    },
    openDetailsDialog() {
      this.detailsDialog = true;
    },
  },
  async mounted() {
    if (!this.profile.plan || !this.profile.plan.isOfferPage) {
      this.$router.push("/");

      return;
    }

    if (this.profile && !this.profile.isFirstTimePremium) {
      this.congratsDialog = true;
    }

    await this.getGroups();

    this.isOfferPageActive = this.profile.isOfferPageActive;
  },
};
</script>

<style lang="scss">
.offers-page {
  width: 100%;
  padding-top: 30px;

  .header {
    background-color: #f4f4f4;
    line-height: 2.5;
    border-radius: 15px;

    .filters {
      button {
        border-radius: 15px;
        font-size: 1.2rem;
        background-color: #fff;
        box-shadow: none;
      }
    }
  }
  .offers-content {
    .section {
      width: 100%;
      .card-parent {
        // justify-content: space-between;
        .menu-slide-card {
          //background-color: aqua;
          border-radius: 30px;
          overflow: hidden;
          height: auto;
          box-shadow: 2px 2px 10px 5px #0000002f;
          height: calc((100% - 72px) / 4);
          .imgss {
            width: 100%;
            height: 200px;
            position: relative;
            overflow: hidden;

            @media (max-width: 599px) {
              height: 150px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            p {
              text-align: center;
              background-color: #fdd039;
              color: black;
            }
          }
          .menu-slide-card__content {
            //  background-color: orange;
            display: flex;
            justify-content: space-between;
            word-wrap: break-word;
            height: 100px;

            .menu-slide-card__left {
              width: 65%;
              color: rgba(0, 0, 0, 1);

              background-color: #fff;
              .menu-slide-card__title {
                font-weight: 800;
              }
            }
            .menu-slide-card__right {
              background-color: #fdd039;
              width: 35%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: #000;
            }
            .menu-slide-card__price {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

// .fullHeight {
//   height: 300px !important;
// }

.offers-dialog {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.congrats-dialog {
  height: 473px;
}
</style>
